/* General App Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.montserrat-regular {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.montserrat-bold {
  font-family: "DM Serif Display", sans-serif;
  font-weight: 900;
  font-style: normal;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #efe8db;
  overflow-x: hidden !important;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
}


/* Hero Section Styles */
.hero-section {
  display: flex;
  flex-direction: column-reverse; 
  align-items: center;
  justify-content: center;
  padding: 50px 20px;

}

/* .hero-content {
  display: flex;
  flex-direction: column;
  gap:10px;
  max-width: 600px;
  text-align: center;
  margin-bottom: 20px;
} */


.cta-button {
  display: flex;
  gap:10px;
  background-color: #ffffff;
  max-width: fit-content;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  border: 1px solid #000;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  margin: auto;
}

.cta-button:hover {
  background-color: #000;
  color:#ffffff
}

.hero-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}
.mycv
{
  border-bottom: 1px solid #000;
  
}

/* Responsive Design */
@media screen and (min-width: 768px)  {
  .cta-button {
    margin: 0;
  }
  .hero-section {
    flex-direction: row;
    text-align: left;
  }

  .hero-content {
    text-align: left;
    margin-bottom: 0;
  }

  .hero-image {
    max-width: 400px;
  }

}

@media (min-width: 1200px) {
  .App {
    padding: 0 40px; /* Increase padding on larger screens */
  }

}
