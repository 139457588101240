/* Figma Prototype Section */
.figma-section {
  /* text-align: center; */
  padding: 50px 20px;
}

.figma
{
  width: 100%;
  height: 100vh;
}

.work-h1
{
  font-size: 36px;
  margin-bottom:30px;
}